import { Option } from '@mwell-healthhub/commons';

import { ExportOptionValues } from '../enums';

export const severityOptions: Option[] = [
  {
    label: 'Mild',
    value: 'mild',
  },
  {
    label: 'Moderate',
    value: 'moderate',
  },
  {
    label: 'Severe',
    value: 'severe',
  },
];

export const criticalityOptions: Option[] = [
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Unable to Assess',
    value: 'unable-to-assess',
  },
];

export const categoryOptions: Option[] = [
  {
    label: 'Food',
    value: 'food',
  },
  {
    label: 'Medication',
    value: 'medication',
  },
  {
    label: 'Environment',
    value: 'environment',
  },
  {
    label: 'Biologic',
    value: 'biologic',
  },
];

export const ExportOptionLabels = {
  [ExportOptionValues.ExportAsCsv]: 'Export as CSV',
  [ExportOptionValues.ExportDigitalID]: 'Export Digital ID',
};

export const exportOptions: Option[] = [
  {
    label: ExportOptionLabels[ExportOptionValues.ExportAsCsv],
    value: ExportOptionValues.ExportAsCsv,
  },
  {
    label: ExportOptionLabels[ExportOptionValues.ExportDigitalID],
    value: ExportOptionValues.ExportDigitalID,
  },
];

export const PROVIDER_ORIGIN = 'https://healthhub-provider.mwell.com.ph';
export const LOCALHOST = 'localhost';

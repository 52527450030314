import { UserUserTypeEnum } from '@healthhub/api-lib';
import { Calendar, Clock, EvTag, Microscope, ProfileCircle, User, Weight } from 'iconoir-react';

import { GroupedRoute } from '@mwell-healthhub/commons';

export const Routes = {
  APPOINTMENT: '/appointments/:id',
  APPOINTMENTS: '/appointments',
  BOOKINGS_ADD: '/appointments/add',
  CLIENTS_ADD: '/clients/add',
  CLIENTS: '/clients',
  DIAGNOSTIC_ORDER_REQUESTS: '/diagnostic-order-requests',
  E_PRESCRIPTIONS: '/e-prescriptions',
  HMO_CARD: '/appointments/:id/hmo-card',
  HMO_LOA: '/appointments/:id/loa',
  HMO_VALID_ID: '/appointments/:id/valid-id',
  HOME: '/partner',
  LAB_REQUESTS: '/lab-requests',
  LOGIN: '/auth/login',
  MEDICINE_ORDERS: '/medicine-orders',
  MESSAGES: '/messages',
  MESSAGE_THREAD: '/messages/:threadId',
  ORDERS: '/orders',
  ORDER: '/orders/:id',
  ORDERS_ADD: '/orders/add',
  ORDERS_EDIT: '/orders/:id/edit',
  OTP: '/auth/otp',
  PAYMENT_METHOD: '/payment-method',
  PHARMACY_PROFILE: '/pharmacy-profile',
  PRACTICES: '/practices',
  PROFILE_EDIT: '/profile/edit',
  PROFILE: '/profile',
  PRODUCTS: '/products',
  PRODUCT: '/products/:id',
  PRODUCTS_ADD: '/products/add',
  PRODUCTS_EDIT: '/products/:id/edit',
  REFERRALS_ADD: '/referrals/add',
  REFERRALS: '/referrals',
  REGISTER: '/auth/register',
  ROOT: '/',
  SCHEDULE: '/schedule',
  SCHEDULE_ADD_CUSTOM: '/schedule/add-custom',
  SCHEDULE_EDIT_CUSTOM: '/schedule/edit-custom/:id',
  SCHEDULE_ADD_TO_EXISTING_CUSTOM: '/schedule/service/:id',
  SERVICES_ADD: '/services/add',
  SERVICES_EDIT: '/services/:id/edit',
  SERVICES_AND_FEES: '/services',
  STAFFS: '/staffs',
  TAGS: '/tags',
  BRANCHES: '/branches',
  BRANCHES_ADD: '/branches/add',
  BRANCH: '/branches/:id/info',
  BRANCH_SHARE_LINKS: '/branches/:id/share-links',
  BRANCH_SERVICES: '/branches/:id/services',
  BRANCH_SERVICE_EDIT: '/branches/:id/services/:serviceId',
  BRANCH_SERVICE_ADD: '/branches/:id/services/add',
  USERS: '/users',
  USERS_EDIT: '/users/:id',
  USERS_ADD: '/users/add',
  VIDEO_SCHEDULE: '/video-schedule',
  VOUCHERS: '/vouchers',
  VOUCHER: '/vouchers/:id',
  VOUCHERS_ADD: '/vouchers/add',
  VOUCHERS_EDIT: '/vouchers/:id/edit',
  ONBOARDING: '/partner/onboarding',
  GETTING_STARTED: '/getting-started',
  AUTH: '/auth',
  SERVICES: '/services',
  PRIVACY_POLICY: 'https://www.mwell.com.ph/privacy-policy',
  TERMS_AND_CONDITIONS: 'https://www.mwell.com.ph/terms-and-conditions',
  MAINTENANCE: '/maintenance',
};

export const PROVIDER_ROUTES = [
  {
    title: 'Manage',
    hasPermission: true,
    routes: [
      {
        name: 'Appointments',
        path: Routes.APPOINTMENTS,
        icon: Calendar,
        hasPermission: true,
      },
      {
        name: 'Clients',
        path: Routes.CLIENTS,
        icon: Weight,
        hasPermission: true,
      },
      {
        name: 'Staffs',
        path: Routes.STAFFS,
        icon: User,
        hasPermission: true,
      },
      {
        name: 'Profile',
        path: Routes.PROFILE,
        icon: ProfileCircle,
        hasPermission: true,
      },
      {
        name: 'Services',
        path: Routes.SERVICES_AND_FEES,
        icon: Microscope,
        hasPermission: true,
      },
      {
        name: 'Schedule',
        path: `${Routes.SCHEDULE}?scheduleType=overview`,
        icon: Clock,
        hasPermission: true,
      },
      {
        name: 'Tags',
        path: Routes.TAGS,
        icon: EvTag,
        hasPermission: true,
      },
    ],
  },
];

export const routes: Record<string, GroupedRoute<React.ElementType>[]> = {
  [UserUserTypeEnum.ProviderAdmin]: PROVIDER_ROUTES,
};

import { Provider } from '@healthhub/api-lib';
import { GetServerSideProps } from 'next';
import { getTokensFromObject } from 'next-firebase-auth-edge/lib/next/tokens';

import { getProviderById } from '@mwell-healthhub/commons';
import { authConfig } from '@mwell-healthhub/commons/config/serverConfig';

export type Props = {
  provider: Provider;
};

export const getProviderSSR: GetServerSideProps<Props> = async (context) => {
  try {
    const { params } = context;
    const providerId = params?.id as string;
    const tokens = await getTokensFromObject(context.req.cookies, authConfig);
    const config = { headers: { Authorization: `Bearer ${tokens?.token}` } };
    const excludes = ['services', 'branches'];

    const provider = await getProviderById(+providerId, config, excludes);

    if (!provider) {
      throw new Error('Provider not found');
    }

    return { props: { provider } };
  } catch (error) {
    console.error(error);
    return { notFound: true };
  }
};
